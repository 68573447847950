import Vue from 'vue'
import store from '../src/store'
import axios from 'axios'
import config from '../config'
import User from './user'
import {isMobile} from "./utils";

const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro

class HttpRequest {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    getInsideConfig() {
        const config = {
            baseUrl: this.baseUrl,
            header: {}
        }
        return config
    }
    interceptors(instance) {
        // 添加请求拦截器
        instance.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            config.headers.token = store.state.user.token;
            return config;
        }, function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        });

        // 添加响应拦截器
        instance.interceptors.response.use(function (response) {
            // 对响应数据做点什么
            const data = response.data;
            if (data === 'downLine' || data === 'loginOutTime') {
                let tips;
                if (data === 'downLine') tips = '您已下线或在其他位置登录，请重新登录！';
                if (data === 'loginOutTime') tips = '您的登录已过期，请重新登录!';
                if (isMobile()) {
                    Vue.prototype.$message.warning(tips);
                    User.logOut(true);
                } else {
                    Vue.prototype.$confirm(tips, "警告", {
                        customClass: 'messageBox',
                        confirmButtonText: "确认",
                        type: "warning",
                        showClose: false,
                        showCancelButton: false,
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                        closeOnHashChange: false
                    }).then(() => {
                        User.logOut(true)
                    })
                }
            } else {
                return response;
            }
        }, function (error) {
            // 对响应错误做点什么
            return Promise.reject(error);
        });
    }
    request(options) {
        const instance = axios.create()
        instance.defaults.withCredentials = true
        options = { ...this.getInsideConfig(), ...options}
        this.interceptors(instance)
        return instance(options)
    }
}

export default new HttpRequest(baseUrl)