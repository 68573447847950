import Vue from 'vue'
import store from '../src/store'
import router from '../src/router'
import sendMsg from './http'
import { linkWebSocket } from "./webSocket";
import { linkSse } from "./sse";

class User {
    login(data) {
        sendMsg.post({
            url: '/login',
            data: data
        }).then(({data : res}) => {
            if (res.code === 20000) {
                // linkWebSocket(res.data.user.id);
                // linkSse(res.data.user.id, res.data.token)
                store.commit('loadInfo', res.data.user)
                // 清除目录
                store.commit('clearMenu')
                // 设置目录
                store.commit('setMenu', res.data.menu)
                // 在cookie中存储用户信息
                store.commit('setToken', res.data.token)
                // 添加动态路由
                store.commit('addMenu', router)
                // 跳转主页
                router.push('/home')
            } else {
                Vue.prototype.$message.warning(res.msg)
            }
        })
    }

    logOut(notSend) {
        let down = false;
        if (notSend) down = notSend;
        if (!down) {
            sendMsg.post({
                url: '/loginOut',
                data: { id: store.state.user.info.id }
            }).then(() => {
                store.commit('clearToken')
                store.commit('clearMenu')
                router.push("/login")
            })
        } else {
            store.commit('clearToken')
            store.commit('clearMenu')
            router.push("/login")
        }
    }

    getUserList(data) {
        return sendMsg.post({
            url: '/user/getUserList',
            data: data
        })
    }

    getAge(birth) {
        let birthDay = new Date(birth).getFullYear()
        let now = new Date().getFullYear()
        return now - birthDay;
    }

    createUser(data) {
        return sendMsg.post({
            url: '/user/createUser',
            data: data
        })
    }

    editUser(data) {
        return sendMsg.post({
            url: '/user/editUser',
            data: data
        })
    }

    editUserByOwn(data) {
        return sendMsg.post({
            url: '/user/editUserByOwn',
            data: data
        })
    }

    delUser(data) {
        return sendMsg.post({
            url: '/user/delUser',
            data: data
        })
    }

    editPWD(data) {
        return sendMsg.post({
            url: '/user/editPWD',
            data: data
        })
    }

    getUserNameByMenu(data) {
        return sendMsg.post({
            url: '/user/getUserNameByMenu',
            data: data
        })
    }
}

export default new User