import Vue from 'vue';
import App from './App.vue';
import './plugins/element.js';
import './router/init';
import router from "@/router";
import store from './store';
import Cookie from "js-cookie";

Cookie.secure = true;
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  store.commit("getToken");
  const token = store.state.user.token;
  if (!token && to.name !== "login") {
    next({name: "login"});
  } else if (token && to.name === "login") {
    next({name: "home"});
  } else if (token && to.name === null) {
    next({name: "home"});
  } else {
    next();
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    // if (sessionStorage.getItem('store')) {
    //     store.replaceState(
    //         Object.assign(
    //             {},
    //             store.state,
    //             JSON.parse(sessionStorage.getItem('store'))
    //         )
    //     )
    // }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    // window.addEventListener('beforeunload', () => {
    //   sessionStorage.setItem('store', JSON.stringify(store.state));
    // })
    // 添加路由
    // store.commit('addMenu', router);
    // store.state.user.info = JSON.parse(store.state.user.uInfo);
  }
}).$mount('#app')
