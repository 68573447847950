<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="less">
  html,body {
    margin: 0;
    padding: 0;
  }
  #app {
    height: 100vh;
  }
  .el-cascader-panel{
    height: 50vh;
  }
</style>
