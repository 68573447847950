import Cookie from "js-cookie";
import {isMobile} from "../../../api/utils";
export default {
    state: {
        // 边栏折叠
        isCollapse: false,
        // 面包屑标签容器
        tabsList: [
            {
                path: "/",
                name: "home",
                label: "首页",
                icon: "s-home",
            }
        ],
        // 面包屑高亮容器
        currentMenu: null,
        menu: [],
        mobileMenu: []
    },
    mutations: {
        // 改变边栏折叠
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse;
        },
        // 改变高亮面包屑标签显示
        selectMenu(state, val) {
            if (val.name !== "home") {
                state.currentMenu = val
                const result = state.tabsList.findIndex(item => item.name === val.name)
                if (result === -1) {
                    state.tabsList.push(val)
                } else {
                    state.currentMenu = null
                }
            }
        },
        // 从面包屑标签容器中删除标签
        closeTag(state, val) {
            const result = state.tabsList.findIndex(item => item.name === val.name)
            state.tabsList.splice(result, 1)
        },
        setMenu(state, val) {
            state.menu = val
            // val.forEach((item, index) => {
            //     Cookie.set('menu' + index, JSON.stringify(item), {expires: 1});
            // })
            // Cookie.set('menu', val.length + '');
        },
        clearMenu(state) {
            state.tabsList = [
                {
                    path: "/",
                    name: "home",
                    label: "首页",
                    icon: "s-home",
                }
            ]
            // state.menu.forEach((e, index) => {
            //     Cookie.remove('menu' + index);
            // })
            state.menu = [];
        },
        addMenu(state, router) {
            // if (!Cookie.get('menu')) {
            //     return
            // }
            // const menu = [];
            // const count = JSON.parse(Cookie.get('menu'))
            // for (let i = 0; i < Number(count); i++) {
            //     menu.push(JSON.parse(Cookie.get('menu' + i)));
            // }
            // state.menu = menu;
            const menuArray = [];
            state.menu.forEach(item => {
                if (item.children) {
                    item.children = item.children.map(item => {
                        addChildren(item)
                        return item
                    })
                    menuArray.push(...item.children)
                } else {
                    addChildren(item)
                    menuArray.push(item)
                }
            });
            state.mobileMenu = menuArray;
            // 路由动态添加
            menuArray.forEach(item => {
                router.addRoute('Main', item)
            })
        },
    }
}

function addChildren(item) {
    if (isMobile()) {
        item.component = () => import(`../../view/mobile/${item.url}`)
    } else {
        item.component = () => import(`../../view/${item.url}`)
    }
}