import Vue from "vue";
import Vuex from "vuex";
import tab from "@/store/modules/tab";
import user from "@/store/modules/user";
import balance from '@/store/modules/balance';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tab,
        user,
        balance
    }
})