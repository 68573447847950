import Cookie from 'js-cookie'
export default {
    state: {
        // 账户信息容器
        token: '',
        info: {},
        uInfo: '',
    },
    mutations: {
        loadInfo(state, val) {
            state.info = val
            state.info.img = state.info.img ? '/images/' + state.info.img : require("../../assets/images/user.png");
            state.uInfo = JSON.stringify(val);
            // Cookie.set("uInfo", state.uInfo, {expires: 1});
        },
        setToken(state, val) {
            state.token = JSON.stringify(val);
            Cookie.set("token", state.token, {expires: 1});
        },
        clearToken(state) {
            state.token = '';
            state.uInfo = '';
            // Cookie.remove('uInfo');
            Cookie.remove("token");
        },
        getToken(state) {
            state.token = state.token || Cookie.get("token")
            // state.uInfo = state.uInfo || Cookie.get('uInfo')
        }
    }
}