import axios from './axios';

class sendMsg {
    post(params) {
        return axios.request({
            url: '/api' + params.url,
            method: 'POST',
            headers: { "Content-Type": "multipart/form-data"},
            data: params.data
        })
    }

    get(params) {
        let param = ''
        for (let i in params.data) {
            if (param !== '') param = param + '&'
            param = param + i + '=' + params.data[i]
        }
        return axios.request({
            url: '/api' + params.url + '?' + param,
            headers: { "Content-Type": "multipart/form-data"},
            method: 'GET'
        })
    }

    fileBlob(params) {
        return axios.request({
            url: '/api' + params.url,
            method: 'POST',
            headers: { "Content-Type": "multipart/form-data"},
            responseType: 'blob',
            data: params.data
        })
    }

    fileArrayBuffer(params) {
        return axios.request({
            url: '/api' + params.url,
            method: 'POST',
            headers: { "Content-Type": "multipart/form-data"},
            responseType: 'arraybuffer',
            data: params.data
        })
    }
}

export default new sendMsg