import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

//默认路由
export const routes = [
    {
        path: '/',
        redirect: '/Main'
    }
]

//pc端的路由
export const pcRoutes = [
    {
        path: '/',
        redirect: '/Main',
    },{
        path: "/Main",
        name: "Main",
        component: () => import("../view/Main"),
        children: [
            {
                path: '/own',
                name: 'own',
                label: '个人中心',
                component: () => import('../view/other/own')
            }
        ]
    }, {
        path: "/login",
        name: "login",
        label: '用户登录',
        component: () => import("../view/login/login")
    }
]

//移动端设备路由
export const mobileRoutes = [
    {
        path: '/',
        redirect: '/Main'
    },{
        path: '/Main',
        name: 'Main',
        component: () => import("../view/mobile/Main"),
        children: [
            {
                path: '/home',
                name: 'home',
                label: '首页',
                component: () => import("../view/mobile/home/index")
            },
            {
                path: '/work',
                name: 'work',
                label: '工作台',
                component: () => import('../view/mobile/work/index'),
                children: []
            },
            {
                path: '/own',
                name: 'own',
                component: () => import('../view/mobile/other/own')
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        label: '用户登录',
        component: () => import("../view/mobile/login/login")
    },

]

// const routes = [
//     {
//         path: "/",
//         name: "Main",
//         component: () => import("../view/Main"),
//         children: [
//             {
//                 path: '/own',
//                 name: 'own',
//                 label: '个人中心',
//                 component: () => import('../view/other/own')
//             }
//         ]
//     },
//     {
//         path: "/login",
//         name: "login",
//         label: '用户登录',
//         component: () => import("../view/login/login")
//     }
// ]

const createRouter = () => new VueRouter({
    mode: "history",
    routes
})

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;