export function checkCode(obj, res) {
    const code = res.data.code;
    if (code === 20000) {
        obj.$message.success(res.data.msg)
    } else {
        obj.$message.warning(res.data.msg)
    }
}

export function getDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return year + '-' + month + '-' +day + ' ' + hours + ':' + minutes + ':' + seconds;
}

// 判断设备是否为移动端的方法
export function isMobile() {
    return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(navigator.userAgent);
}

export function checkPhoneNumber(phoneNumber) {
    return /^(1[34578][0-9])\d{8}$/.test(phoneNumber);
}

export function checkCard(card) {
    return /^[1-9]\d{16}([0-9]|X|x)$/.test(card);
}

export function isNumber(number) {
    if (isNull(number)) return false;
    return /^\d+$/.test(number) && !/\./.test(number);
}

export function isDouble(data) {
    if (isNull(data)) return false;
    const list = String(data).split('\.');
    if (list.length > 2) return false;
    if (list.length === 1) return isNumber(list[0]);
    if (list.length === 2) return isNumber(list[0]) && isNumber(list[1]);
}

export function isNull(data) {
    return typeof data === 'undefined' || data === '' || data === null || data.length === 0;
}

// 日期格式化
export function formatDate(date) {
    let d = new Date(date);
    let m = d.getMonth() + 1;
    let dd = d.getDate();
    return '' + d.getFullYear() + '-' + (m > 9 ? m : '0' + m) + '-' + (dd > 9 ? dd : '0' + dd);
}

// 获取本周日期
export function getWeek() {
    var new_Date = new Date()
    var timesStamp = new_Date.getTime();
    var currenDay = new_Date.getDay();
    var dates = [];
    for(var i = 0; i < 7; i++) {
        var das = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString();
        dates.push(formatDate(das));
    }
    return dates;
}

// 获取最近一周日期
export function getNearWeek() {
    let data = [];
    const date = new Date();
    const temp = 1000 * 60 * 60 * 24;
    for (let i = 6; i >= 0; i--) {data.push(formatDate(new Date(date - temp * i)))};
    return data;
}

import sendMsg from './http';

export function test(data) {
    return sendMsg.post({
        url: '/test',
        data: data
    })
}

export function test2(data) {
    return sendMsg.post({
        url: '/test2',
        data: data
    })
}
